import * as React from 'react';
import { Link } from 'gatsby';
import Header from '../components/header';
import Footer from '../components/footer';

// markup
const NotFoundPage = () => {
  return (
    <div id="__layout">
      <Header fixed={true} />
      <div className="mx-auto flex-1 flex flex-col items-center justify-center bg-white w-full">
        <section className="w-full flex flex-col items-center pt-10 md:px-8 px-4 container min-space-available relative">
          <div className="404-icon py-10 max-w-full">
            <svg
              width="374"
              height="138"
              viewBox="0 0 374 138"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="max-w-full"
            >
              <path
                d="M187.5 0C151.248 0 122 31.7485 122 68.5767C122 106.252 151.66 138 187.5 138C223.34 138 253 106.252 253 68.5767C253 31.7485 223.34 0 187.5 0ZM211.393 104.135L187.5 86.3558L163.607 104.135L172.67 74.9264L148.777 57.1472H178.437L187.5 27.9387L196.563 57.1472H226.223L202.33 74.9264L211.393 104.135Z"
                fill="#FF6B00"
              />
              <path
                d="M3.72492 107.911H61.167V131.829C61.167 133.79 62.9315 135.554 64.892 135.554H85.8691C87.8296 135.554 89.594 133.79 89.594 131.829L89.398 107.715H104.886C107.042 107.715 108.611 105.951 108.611 103.991V87.7185C108.611 85.758 107.042 84.1896 104.886 84.1896H89.398L89.2019 3.80988C89.2019 1.65335 87.4375 0.0849609 85.477 0.0849609H71.3615C69.5971 0.0849609 69.009 0.673109 68.4208 1.26125L0 83.4054V104.187C0 106.147 1.56839 107.911 3.72492 107.911ZM30.7796 83.6015L61.9512 47.1365V83.6015H30.7796Z"
                fill="#102942"
              />
              <path
                d="M268.725 107.911H326.167V131.829C326.167 133.79 327.931 135.554 329.892 135.554H350.869C352.83 135.554 354.594 133.79 354.594 131.829L354.398 107.715H369.886C372.042 107.715 373.611 105.951 373.611 103.991V87.7185C373.611 85.758 372.042 84.1896 369.886 84.1896H354.398L354.202 3.80988C354.202 1.65335 352.437 0.0849609 350.477 0.0849609H336.362C334.597 0.0849609 334.009 0.673109 333.421 1.26125L265 83.4054V104.187C265 106.147 266.568 107.911 268.725 107.911ZM295.78 83.6015L326.951 47.1365V83.6015H295.78Z"
                fill="#102942"
              />
            </svg>
          </div>

          <div className="text-3xl text-primary-500 text-center my-4">
            Sorry, the page you were looking for could not be found.
          </div>

          <div className="text-primary-500 text-center">
            You can return to our{' '}
            <Link to="/" className="underline">
              home page
            </Link>{' '}
            if you can't find what you're looking for.
          </div>

          <div className="grow"></div>

          <div className="bottom-guy self-end mt-2">
            <svg
              width="271"
              height="317"
              viewBox="0 0 271 317"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M148.251 3.83076C146.38 8.43907 150.181 14.7075 156.804 17.856C163.398 20.976 175.665 15.1082 177.566 10.4999C179.437 5.89162 171.346 9.78435 164.953 3.51591C159.683 -1.63623 150.152 -0.777544 148.251 3.83076Z"
                fill="#7A424E"
              />
              <path
                d="M233.718 316.995L254.422 157.279C247.799 149.264 218.369 134.094 196.312 133.836C196.312 133.836 196.398 135.983 195.995 136.87C194.411 140.305 188.335 148.348 169.157 148.348C150.296 148.348 141.743 139.876 139.267 135.496C138.576 134.294 139.065 131.861 139.065 131.861C139.065 131.861 82.7975 136.269 80.2634 160.57L97.1955 316.967H233.718V316.995Z"
                fill="#283042"
              />
              <path
                d="M168.437 156.163C195.62 156.163 207.715 134.953 204 134.438C201.38 134.066 198.788 133.837 196.312 133.808C196.312 133.808 196.398 135.955 195.995 136.842C194.411 140.277 188.335 148.32 169.157 148.32C150.295 148.32 141.743 139.847 139.267 135.468C138.575 134.266 139.065 131.833 139.065 131.833C139.065 131.833 136.301 132.062 132.01 132.663C130.887 132.806 136.329 156.163 168.437 156.163Z"
                fill="#161A23"
              />
              <path
                d="M260.642 184.9C255.459 187.361 258.799 189.88 253.846 192.771C243.652 198.725 232.825 203.505 222.574 209.344L227.037 268.565C233.142 271.456 247.252 273.774 255.171 268.622C271.095 258.232 265.595 210.632 260.642 184.9Z"
                fill="#FDC8A8"
              />
              <path
                d="M260.642 184.9C255.459 187.361 258.799 189.88 253.846 192.771C243.652 198.725 232.825 203.505 222.574 209.344L227.037 268.565C233.142 271.456 247.252 273.774 255.171 268.622C271.095 258.232 265.595 210.632 260.642 184.9Z"
                fill="#F99B6F"
              />
              <path
                d="M270.98 225.173C268.907 187.848 259.663 164.892 259.663 164.892C252.579 150.409 234.409 144.055 219.838 139.647V201.043C222.747 208.485 225.799 215.813 228.823 222.768C233.401 233.301 267.237 229.266 270.98 225.173Z"
                fill="#283042"
              />
              <path
                d="M24.3702 270.597L99.2977 199.927C94.5751 189.165 77.9022 180.607 73.2948 169.787C71.0487 164.52 75.2817 164.492 72.5749 159.454C58.3784 172.392 23.3336 218.303 1.04541 253.338L24.3702 270.597Z"
                fill="#FDC8A8"
              />
              <path
                d="M73.2948 169.787C71.0487 164.52 75.2818 164.492 72.5749 159.454C67.5932 164.005 60.3078 172.878 51.9857 184.013C62.0644 203.276 65.5487 213.494 57.9753 224.944C52.0721 233.874 27.5378 262.096 22.4697 269.195L24.3703 270.597L99.2977 199.927C94.5751 189.165 77.9022 180.607 73.2948 169.787Z"
                fill="#F99B6F"
              />
              <path
                d="M50.5747 180.034C79.2556 139.045 109.779 136.326 124.926 134.752L113.177 195.032C108.916 201.787 90.1118 209.658 82.8263 218.732C75.6273 227.691 53.4831 184.756 50.5747 180.034Z"
                fill="#283042"
              />
              <path
                d="M148.971 45.7632C138.374 44.7328 135.091 62.708 135.091 62.708C132.154 62.2501 132.701 48.8259 137.222 38.3784C140.044 31.8238 156.66 46.5074 148.971 45.7632Z"
                fill="#7A424E"
              />
              <path
                d="M194.498 140.592C194.094 148.95 179.38 151.554 165.5 150.782C151.649 150.066 140.764 142.653 141.167 134.323L146.494 71.324C146.926 62.9947 156.199 56.7262 170.078 57.499C183.929 58.2719 190.322 65.4276 189.89 73.7855L194.498 140.592Z"
                fill="#FDC8A8"
              />
              <path
                d="M143.442 108.132L190.639 114.801L190.437 118.15C182.115 126.508 160.288 133.922 154.961 134.208C150.036 134.465 141.916 129.313 142.348 122.158L143.442 108.132Z"
                fill="#F99B6F"
              />
              <path
                d="M206.65 73.3848L212.467 72.383C217.275 71.6102 220.126 74.9305 219.349 79.9681C217.995 88.6122 211.199 87.0952 208.896 94.5372L198.875 89.8717L206.65 73.3848Z"
                fill="#FDC8A8"
              />
              <path
                d="M130.887 95.0525C133.623 104.155 143.557 115.776 152.081 117.092C160.979 118.466 199.134 113.028 203.972 105.128C217.362 83.2884 225.165 20.1746 180.186 15.5663C168.178 14.3355 153.694 7.23702 144.018 28.0173C136.819 43.531 128.497 87.0953 130.887 95.0525Z"
                fill="#FDC8A8"
              />
              <path
                d="M174.715 3.02956C171.547 -0.834553 175.434 20.0603 166.105 13.6201C161.929 10.7291 151.159 13.3338 144.824 22.8653L188.22 33.971C190.984 34.0283 193.778 34.0855 196.542 34.2287C203.05 34.5435 208.291 39.9246 208.665 46.6224C212.495 44.8478 216.325 43.1018 220.126 41.0409C221.191 4.05998 185.571 16.3393 174.715 3.02956Z"
                fill="#7A424E"
              />
              <path
                d="M135.753 25.0402C133.997 31.8238 135.437 39.4089 146.696 43.8741C166.882 51.8886 172.814 38.8651 201.121 45.935C203.539 46.5361 198.702 52.1748 206.102 58.7009C213.618 65.37 210.163 78.1645 210.163 78.1645C214.338 78.2504 218.225 49.3411 219.003 46.3357C222.775 31.7952 207.197 15.2225 184.188 9.35477C161.209 3.48705 139.526 10.4997 135.753 25.0402Z"
                fill="#7A424E"
              />
              <path
                d="M130.916 78.2505C124.638 87.9538 137.741 121.929 150.872 124.133C166.508 126.738 195.88 119.181 201.726 109.392C212.38 91.5603 211.43 89.6425 205.901 88.4117C202.301 87.6103 197.205 102.666 188.134 101.149C176.788 99.2599 175.204 88.4976 162.188 86.7802C153.866 85.6639 148.971 87.0664 144.22 87.3813C127.057 88.5262 134.227 73.0698 130.916 78.2505Z"
                fill="#7A424E"
              />
              <path
                d="M146.12 94.3086C146.091 94.3372 146.091 94.3945 146.091 94.4231C144.191 101.207 148.654 107.275 156.055 107.99C163.484 108.706 168.38 104.012 170.309 97.2568C170.309 97.2281 146.12 94.3086 146.12 94.3086Z"
                fill="#F6F1EC"
              />
              <path
                d="M253.587 233.101L251.572 229.466L250.103 229.094C250.103 229.094 198.846 144.313 195.678 140.935C193 138.101 194.526 130.86 193.922 129.629C193.922 129.629 192.972 121.672 192.856 116.52C192.856 116.52 190.495 118.523 190.466 118.123C190.409 116.262 191.013 106.817 190.293 105.385C188.364 103.468 186.032 102.867 185.168 108.763C183.296 118.323 179.783 117.894 175.579 116.606C170.107 114.373 166.393 113.829 159.539 111.339C158.704 111.081 157.149 111.282 156.257 113.486C156.055 114.001 155.94 114.487 155.911 114.974C155.738 116.005 155.997 117.035 156.976 117.722C155.508 118.895 154.73 121.443 156.372 122.817C156.573 123.275 156.919 123.675 157.437 123.99C156.631 124.506 155.969 125.45 155.738 126.452C155.306 127.711 155.479 129.114 156.832 129.915C155.882 130.86 155.594 132.634 157.005 134.524C162.62 139.704 162.476 140.191 166.479 141.994C167.602 142.567 169.013 143.139 170.77 143.826C170.827 143.855 170.885 143.883 170.971 143.912C171 143.941 171.058 143.941 171.086 143.941C172.296 144.399 173.649 144.914 175.176 145.486C178.66 146.803 180.647 152.127 186.752 171.619C197.579 207.799 216.066 254.282 222.603 262.24C231.328 271.914 264.703 251.363 253.587 233.101Z"
                fill="#F99B6F"
              />
              <path
                d="M193.922 138.187L258.022 244.779C268.216 261.065 232.883 284.908 219.032 260.235C210.451 250.074 176.702 141.879 178.228 142.509C188.393 146.688 193.922 138.187 193.922 138.187Z"
                fill="#FDC8A8"
              />
              <path
                d="M158.416 128.14C164.751 130.373 166.335 130.573 172.814 133.264C174.571 133.979 175.838 136.04 175.694 138.101C175.521 140.162 172.411 142.051 170.366 141.221C162.131 137.843 164.146 138.759 157.581 132.834C155.853 130.602 156.89 128.598 158.416 128.14Z"
                fill="#FDC8A8"
              />
              <path
                d="M159.626 123.476C167.832 126.367 171.115 127.54 179.553 131.003C181.799 131.919 183.469 134.61 183.267 137.243C183.037 139.905 182.663 140.363 180.042 139.304C169.387 134.925 167.285 133.579 157.523 128.771C154.615 127.254 157.178 122.731 159.626 123.476Z"
                fill="#FDC8A8"
              />
              <path
                d="M159.741 123.16C167.948 126.051 171.23 127.224 179.668 130.688C181.914 131.604 183.584 134.294 183.382 136.928C183.152 139.589 182.346 140.276 179.725 139.217C169.071 134.838 167.401 133.264 157.639 128.455C154.702 126.938 157.293 122.387 159.741 123.16Z"
                fill="#FDC8A8"
              />
              <path
                d="M160.23 118.208C168.437 121.099 171.72 122.273 180.157 125.736C182.403 126.652 184.073 129.342 183.872 131.976C183.641 134.638 183.612 135.182 180.992 134.122C170.337 129.743 167.919 128.312 158.128 123.475C155.22 121.986 157.782 117.464 160.23 118.208Z"
                fill="#FDC8A8"
              />
              <path
                d="M159.568 117.378C167.775 120.269 171.057 121.443 179.495 124.906C181.741 125.822 183.411 128.513 183.209 131.146C182.979 133.808 182.173 134.495 179.552 133.436C168.898 129.056 167.228 127.482 157.466 122.673C154.557 121.156 157.149 116.605 159.568 117.378Z"
                fill="#FDC8A8"
              />
              <path
                d="M159.942 112.713C168.149 115.604 171.432 116.777 179.869 120.241C182.115 121.156 183.785 123.847 183.584 126.48C183.354 129.142 183.325 129.686 180.704 128.627C170.05 124.248 167.602 122.817 157.84 117.979C154.932 116.491 157.495 111.94 159.942 112.713Z"
                fill="#FDC8A8"
              />
              <path
                d="M159.913 112.168C168.12 115.059 171.403 116.233 179.84 119.696C182.086 120.612 183.757 123.303 183.555 125.936C183.325 128.598 182.518 129.285 179.898 128.226C169.243 123.847 167.573 122.272 157.811 117.464C156.429 116.719 156.343 115.517 156.919 114.057C157.725 112.111 159.165 111.939 159.913 112.168Z"
                fill="#FDC8A8"
              />
              <path
                d="M189.257 130.459C189.631 132.033 188.134 132.921 186.147 132.491C184.131 132.005 174.945 118.953 174.628 117.579C178.458 118.752 181.712 118.38 183.411 109.85C184.189 104.584 186.32 104.584 188.969 106.015C189.977 113.257 185.801 119.783 189.257 130.459Z"
                fill="#FDC8A8"
              />
              <path
                d="M187.99 126.767C187.99 126.767 193.087 137.1 200.862 149.694C202.589 152.47 184.851 165.952 184.851 165.952C179.265 148.578 177.422 143.798 174.225 142.624C162.188 138.216 161.67 138.359 163.974 128.971C169.186 107.647 179.437 122.416 187.99 126.767Z"
                fill="#FDC8A8"
              />
              <path
                d="M193.922 138.187L258.022 244.779C268.216 261.066 232.883 284.909 219.032 260.236C210.451 250.074 176.702 141.879 178.228 142.509C188.393 146.688 193.922 138.187 193.922 138.187Z"
                fill="#FDC8A8"
              />
              <path
                d="M159.913 112.168C168.12 115.059 171.403 116.233 179.84 119.696C182.086 120.612 183.757 123.303 183.555 125.936C183.325 128.598 182.518 129.285 179.898 128.226C169.243 123.847 167.573 122.272 157.811 117.464C156.429 116.719 156.343 115.517 156.919 114.057C157.725 112.111 159.165 111.939 159.913 112.168Z"
                fill="#FDC8A8"
              />
              <path
                d="M189.257 130.459C189.631 132.033 188.134 132.921 186.147 132.491C184.131 132.005 174.945 118.953 174.628 117.579C178.458 118.752 181.712 118.38 183.411 109.85C184.189 104.584 186.32 104.584 188.969 106.015C189.977 113.257 185.801 119.783 189.257 130.459Z"
                fill="#FDC8A8"
              />
              <path
                d="M187.99 126.767C187.99 126.767 193.087 137.1 200.862 149.694C202.589 152.47 184.851 165.952 184.851 165.952C179.265 148.578 177.422 143.798 174.225 142.624C162.188 138.216 161.67 138.359 163.974 128.971C169.186 107.647 179.437 122.416 187.99 126.767Z"
                fill="#FDC8A8"
              />
              <path
                d="M258.051 244.779L197.147 143.539C192.827 136.154 189.746 130.258 188.566 127.911C186.492 118.723 189.89 112.683 188.969 106.072C186.32 104.612 184.189 104.64 183.411 109.907C181.712 118.437 178.487 118.78 174.628 117.635C174.657 117.778 174.801 118.036 174.974 118.408C170.597 116.691 166.623 118.15 163.945 129.027C161.641 138.416 162.16 138.272 174.196 142.68C177.393 143.854 179.207 148.605 184.822 166.008C194.786 198.266 213.042 253.194 219.032 260.292C232.912 284.908 274.263 274.575 258.051 244.779Z"
                fill="#FDC8A8"
              />
              <path
                d="M97.7715 316.853C95.8422 314.849 93.4521 312.244 90.4285 308.867C72.3158 288.258 40.4961 253.653 33.7002 250.361C15.2131 236.651 -14.1301 255.743 7.81255 272.458L70.588 316.853H97.7715Z"
                fill="#FDC8A8"
              />
              <path
                d="M46.9464 265.474C54.1454 272.172 94.5463 316.051 95.2374 316.852H97.7715C95.8421 314.849 93.4521 312.244 90.4285 308.867C74.5042 290.777 48.5878 262.297 37.7604 253.338C36.7238 255.57 35.7159 255.027 46.9464 265.474Z"
                fill="#F99B6F"
              />
              <path
                d="M258.051 244.779L197.147 143.539C192.827 136.155 189.746 130.258 188.566 127.911C186.492 118.723 189.89 112.684 188.969 106.072C188.45 105.786 187.961 105.557 187.471 105.414C188.796 106.387 188.623 108.19 188.45 109.449C187.759 114.458 185.945 124.276 187.039 128.598C188.191 133.149 244.286 228.636 246.561 232.471C250.794 239.512 257.302 248.386 256.38 257.058C255.574 264.386 248.231 268.622 241.81 270.54C238.181 271.628 234.524 272.257 230.809 271.399C247.885 279.613 270.807 268.221 258.051 244.779Z"
                fill="#F99B6F"
              />
            </svg>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default NotFoundPage;
